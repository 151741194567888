import {
  required,
  email,
  helpers,
  maxLength,
  minLength,
} from '@vuelidate/validators'

import emailProviders from '@/data/emailProviders.json'

const useValidation = () => {
  const { t } = useI18n()
  const phoneRegex = /[a-zA-Z]|!|@|\$|%|\^|&|{|}|~|`|'|"|<|>|\?|;|\||\{|\]/

  const checkBusinessEmail = (email: string) => {
    return !emailProviders.some((element) => {
      return email.toLowerCase().includes('@' + element)
    })
  }
  const checkIfDateIsPast = (date: string) => {
    const today = new Date()
    today.setTime(today.getTime() - 24 * 60 * 60 * 1000)
    const dateStamp = new Date(date)

    return dateStamp < today
  }

  const isFieldRequired = helpers.withMessage(
    t('common.form.validation.field.error'),
    required,
  )
  const isEmailValid = helpers.withMessage(
    t('common.form.validation.email.invalid'),
    email,
  )
  const isEmailBusiness = helpers.withMessage(
    t('common.form.validation.email.business'),
    (val: string) => checkBusinessEmail(val),
  )
  const isConsentAccepted = helpers.withMessage(
    t('common.form.validation.consent.error'),
    (val: boolean) => val === true,
  )
  const isValueSelected = helpers.withMessage(
    t('common.form.validation.checkbox.error'),
    (val: string) => val !== '',
  )
  const isInvalidPhone = helpers.withMessage(
    t('common.form.validation.phone.invalid'),
    (val: string) => !phoneRegex.test(val),
  )
  const isPhoneTooLong = helpers.withMessage(
    t('common.form.validation.phone.long'),
    maxLength(20),
  )
  const isPhoneTooShort = helpers.withMessage(
    t('common.form.validation.phone.short'),
    minLength(7),
  )
  const isCheckboxGroupRequired = helpers.withMessage(
    t('common.form.validation.checkbox_group.error'),
    required,
  )
  const isDateNotPast = helpers.withMessage(
    t('common.form.validation.date.error'),
    (val: string) => !checkIfDateIsPast(val),
  )

  return {
    isEmailBusiness,
    isEmailValid,
    isConsentAccepted,
    isValueSelected,
    isFieldRequired,
    isInvalidPhone,
    isPhoneTooLong,
    isPhoneTooShort,
    isCheckboxGroupRequired,
    isDateNotPast,
  }
}
export default useValidation
